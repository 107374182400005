import { INavLinkGroup, INavLink } from '@m365-admin/nav';
import React from 'react';
import { StatUser } from '../../utils/statApi/UsersApi';
import hasAccess from '../../utils/authorization/authorizationCheck';
import FeatureFlags from '../../utils/featureFlags/FeatureFlags';
import FeatureFlagContext from '../../utils/featureFlags/FeatureFlagContext';

const getToolbarLinks = (
  handleLinkClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink) => void,
  pathname: string,
  user: StatUser,
): INavLink[] => {
  const toolbarLinks: INavLink[] = [];
  const featureFlags: FeatureFlags = React.useContext(FeatureFlagContext);
  if (hasAccess(user.permissions, 'UsersList:View')) {
    toolbarLinks.push({
      name: 'Security',
      key: 'security',
      href: '/users',
      ariaLabel: 'Security',
      title: 'Security',
      onClick: handleLinkClick,
      isSelected: pathname.startsWith('/users'),
    });
  }

  if (hasAccess(user.permissions, 'CompanyGroupList:View')) {
    toolbarLinks.push({
      name: 'Company Groups',
      key: 'CompanyGroups',
      href: '/companygroups',
      ariaLabel: 'Company Groups',
      title: 'Company Groups',
      isSelected: pathname.startsWith('/companygroups'),
      onClick: handleLinkClick,
    });
  }

  if (hasAccess(user.permissions, 'ProcessExtensionRequest:View')) {
    toolbarLinks.push({
      name: 'Process Extensions',
      key: 'ProcessExtensions',
      href: '/process-extensions',
      ariaLabel: 'Process Extensions',
      title: 'Process Extensions',
      isSelected: pathname.startsWith('/process-extensions'),
      onClick: handleLinkClick,
    });
  }

  if (hasAccess(user.permissions, 'ProcessTemplate:View')) {
    toolbarLinks.push({
      name: 'Process Templates',
      key: 'ProcessTemplates',
      href: '/process-templates',
      ariaLabel: 'Process Templates',
      title: 'Process Templates',
      isSelected: pathname.startsWith('/process-templates'),
      onClick: handleLinkClick,
    });
  }

  if (hasAccess(user.permissions, 'Notification:AddEdit')) {
    toolbarLinks.push({
      name: 'Notifications',
      key: 'Notifications',
      href: '/notifications',
      ariaLabel: 'Notifications',
      title: 'Notifications',
      isSelected: pathname.startsWith('/notifications'),
      onClick: handleLinkClick,
    });
  }

  if (featureFlags.flags.RoleActions && hasAccess(user.permissions, 'Role:AddEdit')) {
    toolbarLinks.push({
      name: 'Role Actions',
      key: 'RoleActions',
      href: '/role-actions',
      ariaLabel: 'Role Actions',
      title: 'Role Actions',
      onClick: handleLinkClick,
      isSelected: pathname.startsWith('/role-actions'),
    });
  }

  if (
    featureFlags.flags.CountryConfiguration &&
    hasAccess(user.permissions, 'CountryConfiguration:AddEdit')
  ) {
    toolbarLinks.push({
      name: 'Countries',
      key: 'Countries',
      href: '/countries',
      ariaLabel: 'Countries',
      title: 'Countries',
      onClick: handleLinkClick,
      isSelected: pathname.startsWith('/countries'),
    });
  }

  if (hasAccess(user.permissions, 'CompanyCreation:View')) {
    toolbarLinks.push({
      name: 'Company Creation',
      key: 'CompanyCreation',
      href: '/company-creation',
      ariaLabel: 'Company Creation',
      title: 'Company Creation',
      onClick: handleLinkClick,
      isSelected: pathname.startsWith('/company-creation'),
    });
  }

  if (hasAccess(user.permissions, 'InvalidServiceContacts:View')) {
    toolbarLinks.push({
      name: 'Invalid Contacts',
      key: 'invalid-contacts',
      href: '/invalid-contacts/?entityStatuses=Active',
      ariaLabel: 'Invalid Contacts',
      title: 'Invalid Contacts',
      onClick: handleLinkClick,
      isSelected: pathname.startsWith('/invalid-contacts'),
    });
  }

  if (hasAccess(user.permissions, 'RoleDescription:View')) {
    toolbarLinks.push({
      name: 'Roles',
      key: 'Roles',
      href: '/role-description/roles',
      ariaLabel: 'Roles',
      title: 'Roles',
      onClick: handleLinkClick,
      isSelected: pathname.startsWith('/role-description'),
    });
  }

  return toolbarLinks;
};

const getNavLinks = (
  handleLinkClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink) => void,
  pathname: string,
  user: StatUser,
): INavLink[] => {
  const reportsUrl = 'reports-dashboard';
  const links: INavLink[] = [
    {
      key: 'home',
      name: 'Home',
      icon: 'Home',
      ariaLabel: 'homepage',
      url: '/',
      isSelected: pathname === '/',
      onClick: handleLinkClick,
    },
    {
      key: 'companies',
      name: 'Companies',
      icon: 'CityNext',
      href: '/companies',
      ariaLabel: 'Companies',
      isSelected: pathname.startsWith('/companies'),
      onClick: handleLinkClick,
    },
    {
      key: 'reports',
      name: 'Reports',
      icon: 'ReportDocument',
      href: `/${reportsUrl}`,
      ariaLabel: 'Reports',
      isSelected: pathname.startsWith(`/${reportsUrl}`),
      onClick: handleLinkClick,
    },
  ];

  const processesLink: INavLink = {
    key: 'processes',
    name: 'Processes',
    icon: 'TimelineProgress',
    href: '/processes',
    ariaLabel: 'Processes',
    isSelected: pathname.startsWith('/processes'),
    onClick: handleLinkClick,
  };
  links.splice(2, 0, processesLink);

  const dividendsReviewsLink: INavLink = {
    key: 'DividendsReviews',
    name: 'Dividends Reviews',
    icon: 'ThumbnailView',
    href: '/dividends-reviews/?statuses=PENDING',
    ariaLabel: 'Dividends Reviews',
    title: 'Dividends Reviews',
    isSelected: pathname.startsWith('/dividends-reviews'),
    onClick: handleLinkClick,
  };
  links.splice(3, 0, dividendsReviewsLink);

  if (hasAccess(user.permissions, 'AgmTracker:View')) {
    const agmTrackerLink: INavLink = {
      key: 'AgmTracker',
      name: 'AGM Tracker',
      icon: 'Globe',
      href: '/agm-tracker',
      ariaLabel: 'AGM Tracker',
      title: 'AGM Tracker',
      isSelected: pathname.startsWith('/agm-tracker'),
      onClick: handleLinkClick,
    };
    links.splice(6, 0, agmTrackerLink);
  }

  const toolsLinks = getToolbarLinks(handleLinkClick, pathname, user);

  if (toolsLinks.length) {
    links.push({
      key: 'tools',
      name: 'Tools',
      icon: 'DeveloperTools',
      ariaLabel: 'Tools',
      links: toolsLinks,
    });
  }

  return links;
};

const navLinkGroups = (
  handleLinkClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink) => void,
  pathname: string,
  user: StatUser,
): INavLinkGroup[] => {
  const navLinks: INavLinkGroup[] = [
    {
      key: 'mainNav',
      links: getNavLinks(handleLinkClick, pathname, user),
    },
  ];

  return navLinks;
};

export default navLinkGroups;

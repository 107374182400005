import React from 'react';
import { Stack } from '@fluentui/react';
import { GET_DOCUMENTS_TO_ANALYZE } from '../../../utils/statApi/DocumentAnalysisApi';
import documentsToAnalyzeColumns from './DocumentsToAnalyze.config';
import Pagination from './Pagination';
import FilterCallout from '../../common/callout/FilterCallout';
import DocumentAnalysisFilter, { IDocumentAnalysisFilter } from './DocumentAnalysisFilter';
import useUrlFiltering from '../../../utils/hooks/useUrlFiltering';

const dateKeys = ['reportingPeriodEndFromDate', 'reportingPeriodEndToDate'];
const arrayKeys = ['companyCodes', 'uploaderIds'];
const numberKeys = ['uploaderIds'];

const defaultFilterObject: IDocumentAnalysisFilter = {
  companyCodes: undefined,
  reportingPeriodEndFromDate: undefined,
  reportingPeriodEndToDate: undefined,
  selectedPeriodEndDateOption: undefined,
  uploadedFromDate: undefined,
  uploadedToDate: undefined,
  uploaderIds: undefined,
  documentName: undefined,
};

const DocumentsToAnalyzeList = () => {
  const [searchState, setSearchState] = useUrlFiltering<IDocumentAnalysisFilter>({
    defaultFilterObject,
    dateParameterKeys: dateKeys,
    arrayParameterKeys: arrayKeys,
    numberParameterKeys: numberKeys,
  });

  const onResetFilters = (): void => {
    setSearchState({ filter: defaultFilterObject });
  };

  const updateStateResetPagination = (newState: IDocumentAnalysisFilter): void => {
    setSearchState({ filter: newState });
  };

  return (
    <Pagination
      columns={documentsToAnalyzeColumns}
      query={GET_DOCUMENTS_TO_ANALYZE}
      label="Management Letters"
      queryResultKey="documentsForAnalysis"
      defaultSortColumn="document.title"
      searchDto={searchState.filter}
    >
      <Stack horizontalAlign="end">
        <FilterCallout triggerButtonId="ProcessSearchFilter" triggerIconName="Filter">
          <DocumentAnalysisFilter
            onResetFiltersClick={onResetFilters}
            filterState={searchState.filter}
            onUpdateState={updateStateResetPagination}
          />
        </FilterCallout>
      </Stack>
    </Pagination>
  );
};

export default DocumentsToAnalyzeList;

interface IConfig {
  [settings: string]: {
    appBuildId: string;
    azureAdAppClientId: string;
    azureAdScopeUriPrefix: string;
    azureAdTenantId: string;
    azureAdAuthority: string;
    ocvAppId: string;
    ocvEnvironment: string;
    ocvAudienceGroup: string;
    ocvChannel: string;
    baseURI: string;
    maxSizeMb: string;
    validUploadExtensionsRegex: string;
    downloadOnlyExtensionsRegex: string;
    appInsightsInstrumentationKey: string;
  };
}
const config: IConfig = {} as IConfig;
const loadConfiguration = (rootUrl?: string): Promise<void> => {
  const configVersion = '12773276';
  let configPath = `/config${configVersion}.json`;
  if (configVersion.indexOf('##') === 0) {
    configPath = '/config.json';
  }

  if (rootUrl?.length > 0) {
    configPath = `${rootUrl}${configPath}`;
  }

  return fetch(configPath)
    .then((result) => result.json())
    .then((newConfig: IConfig) => {
      Object.keys(newConfig).forEach((keyName) => {
        config[keyName] = newConfig[keyName];
      });
    });
};
export default config;
export { loadConfiguration };
